import React, { memo } from 'react';
import { grand_rounds_static_image } from '../../../common/assets.common';
import { removeHtmlTags } from '../../../pages/channel/utils';
import LazyImage from '../../CustomLibraries/LazyImage';

import ContentCard from '../contentBasedCard/components/ContentCard';

import useRedirect from '../../customHooks/useRedirect';
import useShare from '../../customHooks/useShare';
import { useTrackUserActivity } from '../../customHooks/useTrackUserActivity';
import useContentAccessRedirect from '../../../common/useContentAccessRedirect';

const RelatedCard = ({
  featured = false,
  type = '',
  className = null,
  image = null,
  title = '',
  contentType = '',
  sessionDoctorDetails = [],
  sponsor_logo = [],
  icon,
  type_id,
  internalType,
  id,
  sponsor_id,
  survey_id,
  pageId,
  fallbackImage = '',
  premiumContent,
  price = 20,
  userContentPayment = null,
  displayIcon,
  onClick = () => {}
}) => {
  const { userActivityTracker } = useTrackUserActivity();
  const { contentAccessRedirect } = useContentAccessRedirect();
  const { redirectTo } = useRedirect();
  const share_url = useShare();

  const cardOptions = {
    flexDirection: type === 'small' ? 'row' : 'column'
  };
  // if (!type_id) return null;
  // else
  return (
    <ContentCard
      className={`clr_v3_card--related ${className ?? ''}`}
      isLocked={displayIcon}
      type={type}
      options={{
        rounded: '3'
      }}
      data={{
        type_id,
        type
      }}
    >
      {displayIcon ? (
        <ContentCard.PremiumTag
          options={{
            iconSize: { default: '24', lg: '24' },
            fontSize: { default: '4', lg: '16' }
          }}
        ></ContentCard.PremiumTag>
      ) : (
        <></>
      )}
      <ContentCard.Container
        options={{ padding: { default: '3', md: '3' }, gap: { default: '2', md: '2' } }}
      >
        {type != 'small' && (
          <ContentCard.Header>
            {contentType && (
              <ContentCard.Tag
                options={{
                  iconName: `${icon}`,
                  iconSize: '16'
                }}
              >
                {contentType}
              </ContentCard.Tag>
            )}
          </ContentCard.Header>
        )}

        <ContentCard.Body options={cardOptions}>
          <ContentCard.Image onClick={onClick} options={{ rounded: '3' }}>
            <LazyImage
              src={image}
              alt="banner"
              fallback_image={fallbackImage ?? grand_rounds_static_image}
              className={`object-fit-cover w-100 h-100 transition`}
              extraClass="hello boss w-100 h-100 position-relative hover-image"
            />
          </ContentCard.Image>
          {title || contentType ? (
            <ContentCard.Content
              onClick={onClick}
              className={`${type === 'small' ? 'justify-content-center' : ''}`}
            >
              {type === 'small' && (
                <ContentCard.Header>
                  {contentType && (
                    <ContentCard.Tag
                      options={{
                        iconName: `${icon}`,
                        iconSize: '16'
                      }}
                    >
                      {contentType}
                    </ContentCard.Tag>
                  )}
                </ContentCard.Header>
              )}
              {title && (
                <ContentCard.Title options={{ fontWeight: 'medium', fontSize: '4' }}>
                  {removeHtmlTags(title)}
                </ContentCard.Title>
              )}
            </ContentCard.Content>
          ) : (
            <></>
          )}
          {type != 'small' ? (
            <ContentCard.Footer>
              <ContentCard.ButtonContainer onClick={onClick} className="ms-auto">
                <ContentCard.Button
                  options={{ variant: 'outline-primary' }}
                  className="rounded-2 fs-5 py-2 px-3 "
                  onClick={onClick}
                >
                  View Details
                </ContentCard.Button>
              </ContentCard.ButtonContainer>
            </ContentCard.Footer>
          ) : (
            <></>
          )}
        </ContentCard.Body>
      </ContentCard.Container>
    </ContentCard>
  );
};

export default memo(RelatedCard);
