import { useInfiniteQuery, useQuery } from 'react-query';
import axiosInstance from '../../Store/helper/axiosInstance';
import {
  FILTERED_LIST,
  FILTERED_LIST_CME,
  FRESH_CONTENT,
  LINKED_CONTENTS,
  RECOMMENDED_CONTENTS,
  RELATED_TOPICS,
  SIMILAR_CONTENT,
  SPECIALITY_LIST
} from '../QueryKeys/common.key';
import { generateUtmExt } from '../../common/common';
const staleTime = 300000;
const cacheTime = 600000;
export const useSimilarContent = (id, service_id) => {
  return useInfiniteQuery(
    [SIMILAR_CONTENT, id, service_id],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `related/similar_content?type_id=${id}&service_id=${service_id}&from=${pageParam}&to=5`
      ),
    {
      enabled: !!id,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

export const useRelatedTopics = (id, service_id) => {
  return useInfiniteQuery(
    [RELATED_TOPICS, id, service_id],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `related/topics?type_id=${id}&service_id=${service_id}&from=${pageParam}&to=5`
      ),
    {
      enabled: !!id,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

export const useLinkedContent = (id, type) => {
  return useInfiniteQuery(
    [LINKED_CONTENTS, id, type],
    ({ pageParam = 0 }) =>
      axiosInstance.get(`related/linked_content?type_id=${id}&type=${type}&from=${pageParam}&to=5`),
    {
      enabled: !!id,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

export const useRecommendedContent = (id, enabled, speciality_ids = '') => {
  return useInfiniteQuery(
    [RECOMMENDED_CONTENTS, id, speciality_ids],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `related/trending?type=all&speciality=${speciality_ids}&type_id=${id}&from=${pageParam}&to=5`
      ),
    {
      enabled: enabled,
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0) {
          return allPages.length * 5;
        } else {
          return undefined;
        }
      }
      // onSuccess,
      // select: res => res.data.data
    }
  );
};

export const useSpecialityList = () => {
  return useQuery([SPECIALITY_LIST], () => axiosInstance.get(`authnew/getallspeciality`), {
    staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false,
    select: (res) => res?.data?.data?.speciality_data
  });
};

export const useFilteredList = (type, specialities = null, extratype, contentType = null) => {
  return useInfiniteQuery(
    [FILTERED_LIST, type, specialities, extratype, contentType],
    ({ pageParam = 0 }) =>
      axiosInstance.get(
        `knwlg/${
          type === 'archived_video'
            ? 'archiveVideoList'
            : contentType === 'toprated'
            ? 'topratedmedwiki'
            : contentType === 'mostcommented'
            ? 'topcommentedmedwiki'
            : 'feed'
        }?from=${pageParam}&to=10${specialities !== null ? `&spIds=${specialities}` : ''}${
          extratype === 'video' ? '&type=1' : ''
        }`
      ),
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0 && lastPage?.data?.data?.length === 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      },
      // onSuccess,
      // select: res => res.data
      select: (data) => ({
        ...data,
        pages: data.pages.map((x) => x.data.data)
      })
    }
  );
};

export const useFilteredListCME = (
  applyFilter = false,
  dateTo = '',
  dateFrom = '',
  showMasterConsult = false,
  showMasterCircle = false,
  showMasterCast = false,
  showPodCast = false,
  showHotline = false,
  showMultidaysession = false,
  showExpressCast = false,
  specialityIds = '',
  enabled = true
) => {
  return useInfiniteQuery(
    [
      FILTERED_LIST_CME,
      specialityIds,
      dateTo,
      dateFrom,
      showMasterConsult,
      showHotline,
      showMultidaysession,
      showExpressCast,
      showMasterCircle,
      showMasterCast,
      showPodCast,
      specialityIds
    ],
    ({ pageParam = 0 }) => {
      if (applyFilter) {
        const payload = {
          short_options: { shortBySpeakerName: '', shortByAddOn: '' },
          filter_options: {
            dateTo: dateTo,
            dateFrom: dateFrom,
            showMasterConsult: showMasterConsult,
            showHotline: showHotline,
            showMultidaysession,
            showExpressCast: showExpressCast,
            showMasterCircle: showMasterCircle,
            showMasterCast: showMasterCast,
            showPodCast: showPodCast,
            showAvailableSession: true,
            showUpcomingSession: true,
            showCompleteSession: false,
            speakerType: '',
            specialityIds: specialityIds
          },
          type: 'session',
          from: pageParam,
          to: 10
        };
        return axiosInstance.post(`knwlgmastersessionnew/filterSession`, payload);
      } else {
        return axiosInstance.get(
          `knwlgmastersessionnew/upcomingmastersessionslider?from=${pageParam}&to=10`
        );
      }
    },
    {
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false,
      enabled: !!enabled,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.data?.data?.length > 0 && lastPage?.data?.data?.length === 10) {
          return allPages.length * 10;
        } else {
          return undefined;
        }
      },
      // onSuccess,
      // select: res => res.data
      select: (data) => ({
        ...data,
        pages: data.pages.map((x) => x.data.data)
      })
    }
  );
};

function getFreshContentData({ type, id }) {
  if (!id) return null;
  switch (type) {
    case 'comp':
      return axiosInstance.get(`knwlg/feedDetail?type_id=${id}&type=comp${generateUtmExt()}`);
    case 'clinical_video':
      return axiosInstance.get(`knwlg/archiveVideoDetail?type_id=${id}${generateUtmExt()}`);
    case 'gr':
      return axiosInstance.get(`gr/detail?type_id=${id}${generateUtmExt()}`);
    case 'session':
      return axiosInstance.get(
        `knwlgmastersessionnew/sessiondetail_new?session_id=${id}${generateUtmExt()}`
      );
    case 'epub':
      return axiosInstance.get(`epub/detail?type_id=${id}${generateUtmExt()}`);
    case 'spq':
      return axiosInstance.get(`survey/detail?id=${id}${generateUtmExt()}`);
    case 'training':
      return axiosInstance.get(`training/detail?id=${id}${generateUtmExt()}`);
    default:
      return axiosInstance.get(`knwlg/feedDetail?type_id=${id}&type=comp${generateUtmExt()}`);
  }
}

export const useGetFreshContentData = (type, id, onSuccess, onError) => {
  return useQuery([FRESH_CONTENT, type, id], () => getFreshContentData({ type, id }), {
    staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false,
    select: (res) => {
      console.log('getFreshContentData', type, id);

      return Array.isArray(res?.data?.data)
        ? res?.data?.data[0]
        : type == 'survey'
        ? { ...res?.data?.data, type: 'survey' }
        : type == 'training'
        ? { ...res?.data?.data, type: 'training' }
        : res?.data?.data;
    },

    onError,
    onSuccess,
    enabled: !!id && !!type
  });
};
