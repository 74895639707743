import React from 'react';
import Card from '../../components/baseUiV3/components/Card';
import { Skeleton } from '../Skeleton';

function RelatedCardSkeleton({ type }) {
  return (
    <Card className={`clr_v3_card--related `} type={type}>
      <Card.Body>
        <div
          className={`d-flex gap-4 ${
            type === 'small' ? 'flex-row align-items-center' : 'flex-column'
          }`}
        >
          {type === 'small' ? (
            <Skeleton variant="text" height={70} width="33%" className="" />
          ) : (
            <Skeleton height={200} width="100%" />
          )}

          <div className="d-flex flex-column flex-1">
            <span className="clr_v3_card__icon d-inline-flex align-items-center justify-content-center me-auto cursorPointer gap-2 text-black">
              <Skeleton variant="circular" height={15} width={15} />
              <Skeleton variant="rectangular" width={50} height={10} />
            </span>

            <Card.Title className="hover-opacity mt-1 cursorPointer flex-1">
              <Skeleton variant="text" width="100%" />
              <Skeleton variant="text" width="100%" />
            </Card.Title>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default RelatedCardSkeleton;
