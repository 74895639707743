import React, { useEffect, memo, useRef } from 'react';
// import WorkerBuilder from './worker/woker-builder';
import TrackerWorker from './worker/tracker.worker';
import dayjs from 'dayjs';
import {
  getAgent,
  getSessionStorage,
  setSessionStorage,
  getLocalStorage,
  removeSessionStorage,
  log,
  isMobileApp,
  generateUUID
} from '../common/common';
import Package from '../../package.json';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearTrackingData,
  recordEvent,
  recordTrackingData
} from '../Store/actions/tracking.action.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { onPageView } from './actions/UserActions.js';
import {
  PAGE_VIEW,
  INNITIALIZE_PAGE_CONFIG,
  GET_REPORT_DATA,
  SEND_REPORT_SUCCESS,
  IMMEDIATE_REPORT
} from './constants/ActionType.js';
import useNetwork from '../components/customHooks/useNetwork.js';
import ReactGA from 'react-ga4';
import { changeShowSearch, updateCurrentlyOpenedMenu } from '../Store/actions/commonActions.js';
import { showOneSignalPrompt } from '../common/onesignal.utils.js';
import { useTrackUserActivity } from '../components/customHooks/useTrackUserActivity';
import OnesignalAppInit from '../capacitor/OnesignalAppInit.jsx';

const apiUrl = process.env.REACT_APP_API_URL;

let appVersion = Package.version;

let pageSessionId;

/** use tracker hook start  */
export function useTracker(page_name = '', page_id = '', appVersion = '', networkState = '') {
  const { userActivityTracker } = useTrackUserActivity();
  const dispatch = useDispatch();
  const trackActivityEvent = (type = '', data = null, immediateFire = false) => {
    // if (type === PAGE_VIEW) {

    userActivityTracker(true, { ...data }, 'card');
    // }

    //console.log('RECORD TYPE DATA', type, data);
    let activity_events = getSessionStorage('activity_events', false)
      ? JSON.parse(getSessionStorage('activity_events', '[]'))
      : [];
    activity_events.push(data);
    //console.log(activity_events);
    if (!immediateFire) {
      setSessionStorage('activity_events', JSON.stringify(activity_events));
    } else {
      reportData(
        getConfigData(page_name, page_id, appVersion, networkState),
        [data],
        'immediateFire',
        dispatch,
        type
      );
    }
  };
  return { trackActivityEvent };
}
let trackerWorker;
/** use tracker hook end  */
const TrackerComponent = ({ page_name = '', page_id = 0, module_page = '' }) => {
  const networkState = useNetwork();
  const dispatch = useDispatch();
  const trackingData = useSelector((state) => state.tracking.tracking_data);
  const activityEvents = useSelector((state) => state.tracking.activity_event);
  const { state } = useLocation();
  const trackingDataRef = useRef({});
  const activityEventsRef = useRef([]);
  const { trackActivityEvent } = useTracker(page_name, page_id, appVersion, networkState);
  const isSearchOpen = useSelector((state) => state.common.isSearchOpen);
  const tracking_config = () =>
    getSessionStorage('tracking_config', false)
      ? JSON.parse(getSessionStorage('tracking_config', '{}'))
      : {};
  const activity_events = () =>
    getSessionStorage('activity_events', false)
      ? JSON.parse(getSessionStorage('activity_events', '[]'))
      : [];

  /** send analytics function  start  */

  /** send analytics function  end  */

  /** activity events watcher of redux store start*/
  useEffect(() => {
    if (activityEvents && activityEvents?.length > 0) {
      activityEventsRef.current = activityEvents;
      // action_dispatcher({ type: 'EVENTS', id: todo.id });
      //   console.log('ACTIVITY EVENTS', activityEvents);
      // setSessionStorage('tracking_config', JSON.stringify(newConfigData));
      let activities = getSessionStorage('activity_events', false)
        ? JSON.parse(getSessionStorage('activity_events', '[]'))
        : [];
      !activities?.some((act) => act.timestamp === activityEvents[0].timestamp) &&
        setSessionStorage('activity_events', JSON.stringify([...activities, ...activityEvents]));
    }
    if (trackingData && typeof trackingData == 'object' && Object.keys(trackingData).length > 0) {
      setSessionStorage('tracking_config', JSON.stringify(trackingData));
      trackingDataRef.current = trackingData;
    }
    return () => {};
  }, [activityEvents, trackingData]);

  useEffect(() => {
    //generate a page session id
    pageSessionId = generateUUID();
  }, []);

  // exit from app
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const handleMobileBackButtonPress = async () => {
    const { App } = await import('@capacitor/app');
    App.addListener('backButton', (data) => {
      if (data.canGoBack) {
        if (pathname == '/dashboard' || pathname == '/') {
          if (window.confirm('Are you sure you want to exit?')) {
            App.exitApp();
          }
        } else {
          navigate(-1);
        }
      }
    });
  };
  useEffect(() => {
    if (isMobileApp()) {
      handleMobileBackButtonPress();
    }
  }, [pathname]);

  /** activity events watcher of redux store end*/

  useEffect(() => {
    let currentLocation = '';
    let pageTitle = '';
    pageTitle = document.title;
    currentLocation = window.location.href;

    dispatch(updateCurrentlyOpenedMenu(module_page));
    isSearchOpen && dispatch(changeShowSearch(false));

    if (!isMobileApp()) {
      state?.from_login && showOneSignalPrompt();
    }

    ReactGA.event({
      category: 'PAGE_LOADED',
      action: 'PAGE LOADED',
      value: getLocalStorage('user', false)
        ? parseInt(getLocalStorage('user', '{}')?.user_master_id)
        : 0,
      label: currentLocation, // optional
      nonInteraction: true, // optional, true/false
      transport: 'beacon' // optional, beacon/xhr/image
    });

    ReactGA.send({
      hitType: 'pageview',
      page: currentLocation,
      title: pageTitle,
      location: currentLocation
    });

    return () => {
      ReactGA.event({
        category: 'PAGE_EXIT',
        action: 'PAGE EXIT',
        page_title: pageTitle,
        page_location: currentLocation,
        value: getLocalStorage('user', false)
          ? parseInt(getLocalStorage('user', '{}')?.user_master_id)
          : 0,
        label: currentLocation, // optional
        nonInteraction: true, // optional, true/false
        transport: 'beacon' // optional, beacon/xhr/image
      });
    };
  }, [page_name, page_id]);

  useEffect(() => {
    const interval = setInterval(() => {
      reportData(tracking_config(), activity_events(), 'xhr', dispatch); //page er beacon
      trackingDataRef.current = '';
      activityEventsRef.current = '';
    }, parseInt(process.env.REACT_APP_REPORTING_INTERVAL));

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let visibilityChange = null;
    if (typeof document.hidden !== 'undefined') {
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      visibilityChange = 'webkitvisibilitychange';
    }
    document.addEventListener(visibilityChange, reportOnVisibilityChange);
    document.addEventListener(
      'pagehide',
      () => {
        reportData(tracking_config(), activity_events(), 'pagehide', dispatch);
        trackingDataRef.current = '';
        activityEventsRef.current = '';
      },
      false
    );

    let tempConfigData = getConfigData(page_name, page_id, appVersion, networkState);
    if (typeof Worker !== 'undefined') {
      // alert('inside worker');
      //  console.log('tempConfigData\n' + JSON.stringify(tempConfigData));
      // trackerWorker = new WorkerBuilder(WorkerScript);
      trackerWorker = new TrackerWorker();
      trackerWorker.postMessage({
        action: INNITIALIZE_PAGE_CONFIG,
        data: tempConfigData
      });
      trackerWorker.onmessage = (response) => {
        if (response) {
          // alert(JSON.stringify(response?.data));
          let { action, data } = response.data;
          //console.log('Time Taken' + response.data.data + '\n' + typeof trackerWorker);
          workerActionHandler(action, data);
        }
      };
    } else {
      //console.log('inside else \n' + JSON.stringify(tracking_config()));

      dispatch(recordTrackingData(tempConfigData));
      trackActivityEvent(PAGE_VIEW, onPageView(page_name, page_id));
      // setTimeout(() => {
      //console.log('something data\n' + JSON.stringify(tracking_config()));
      reportData(tracking_config(), activity_events(), 'pagehide', dispatch); //page er beacon
      trackingDataRef.current = '';
      activityEventsRef.current = '';
      // }, 500);
    }

    return () => {
      if (tracking_config() && activity_events()) {
        reportData(tracking_config(), activity_events(), 'xhr', dispatch);
        trackingDataRef.current = '';
        activityEventsRef.current = '';
      }

      document.removeEventListener(visibilityChange, reportOnVisibilityChange);
      document.removeEventListener('pagehide', () => {
        reportData(tracking_config(), activity_events(), 'remove', dispatch);
      });

      if (trackerWorker && typeof trackerWorker == 'object') {
        // trackerWorker.terminate();
      }
      removeSessionStorage('tracking_config');
      removeSessionStorage('activity_events');
    };
  }, [page_name, page_id]);
  // useEffect(() => {
  //   console.log('this=============', activityEventsRef.current);
  //   return () => {
  //     console.log('this=============', activityEventsRef.current);
  //   };
  // }, [activityEventsRef.current]);

  const reportOnVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      //console.log(`visibility change reporting`);
      if (tracking_config() && tracking_config()?.start_time && activity_events()) {
        reportData(tracking_config(), activity_events(), 'pagehide', dispatch);
        trackingDataRef.current = '';
        activityEventsRef.current = '';
      }
    } else {
      if (typeof TrackerWorker !== 'undefined') {
        let newConfigData = getConfigData(page_name, page_id, appVersion, networkState);
        trackerWorker.postMessage({
          action: INNITIALIZE_PAGE_CONFIG,
          data: newConfigData
        });
      }
    }
  };

  const workerActionHandler = (action = '', data = {}) => {
    //console.group(`tracking worker message recived`);
    //console.log(action + '::');
    //console.groupEnd(`tracking worker message recived`);
    switch (action) {
      case INNITIALIZE_PAGE_CONFIG:
        trackActivityEvent(PAGE_VIEW, onPageView(page_name, page_id));
        dispatch(recordTrackingData(data));
        reportData(data, activityEventsRef.current, 'pageview', dispatch);

        break;

      case SEND_REPORT_SUCCESS:
      // trackActivityEvent(PAGE_VIEW, onPageView(page_name, page_id));
      // console.log(JSON.stringify(data));

      default:
        break;
    }
  };

  return <>{state?.from_login && isMobileApp() == true && <OnesignalAppInit />}</>;
};

export default memo(TrackerComponent);
const getCurrentTime = () => dayjs(new Date().getTime()).format('HH:mm:ss DD:MM:YYYY');

/** configuring referer url from session storage start  */
const configureReferer = (url) => {
  let tempRefArr = [];
  tempRefArr = getSessionStorage('refer_url', []);
  if (tempRefArr && Array.isArray(tempRefArr) && tempRefArr.length > 0) {
    if (!tempRefArr[0]) {
      tempRefArr[0] = url;
    }
    if (url != tempRefArr[1]) {
      tempRefArr[1] = url;
    }
  } else {
    tempRefArr = [];
    tempRefArr[0] = url;
  }
  setSessionStorage('refer_url', tempRefArr);
  return tempRefArr[0];
};
/** configuring referer url from session storage end  */

const reportData = (config = {}, activity = [], type = '', dispatch, eventType = '') => {
  let tempPageReport = config;
  // console.group(`reporting tarcking data for ${page_name}/${page_id}`);
  // console.log(type + 'reporting data\n' + JSON.stringify(config));
  // console.groupEnd(`reporting tarcking data for ${page_name}/${page_id}`);
  //console.log('DEBUG', tempPageReport, type);
  // return false;
  switch (type) {
    case 'immediateFire':
      if (tempPageReport) {
        tempPageReport.type = eventType;
        tempPageReport.activity_events = activity;
        tempPageReport.end_time = getCurrentTime();
        // console.log('SEND BEACON', tempPageReport.token);
        if (
          tempPageReport.page &&
          tempPageReport.start_time &&
          !!tempPageReport.token &&
          tempPageReport.token != 0
        ) {
          //  let s = navigator.sendBeacon.bind(navigator);
          //   s(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, JSON.stringify(tempPageReport));
          // if (navigator) {
          //   navigator.sendBeacon(
          //     process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
          //     JSON.stringify(tempPageReport)
          //   );
          // }
        } else {
          // navigator.sendBeacon(`${apiUrl}openapi/tracking`, JSON.stringify(tempPageReport));
        }
        /** send events to firestore  */
        if (trackerWorker) {
          trackerWorker.postMessage({
            action: GET_REPORT_DATA,
            data: {
              token: tempPageReport.token,
              report: tempPageReport
            }
          });
        } else {
          navigator.sendBeacon(
            process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
            JSON.stringify(tempPageReport)
          );
        }
      }
      break;
    case 'pageview':
      if (tempPageReport) {
        tempPageReport.type = 'PAGE_LOADED';
        tempPageReport.activity_events = activity;
        // console.log('SEND BEACON', tempPageReport.token);
        if (
          tempPageReport.page &&
          tempPageReport.start_time &&
          !!tempPageReport.token &&
          tempPageReport.token != 0
        ) {
          //  let s = navigator.sendBeacon.bind(navigator);
          //   s(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, JSON.stringify(tempPageReport));
          // if (navigator) {
          //   navigator.sendBeacon(
          //     process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
          //     JSON.stringify(tempPageReport)
          //   );
          // }
        } else {
          // navigator.sendBeacon(`${apiUrl}openapi/tracking`, JSON.stringify(tempPageReport));
        }
        /** send events to firestore  */
        if (trackerWorker) {
          trackerWorker.postMessage({
            action: GET_REPORT_DATA,
            data: {
              token: tempPageReport.token,
              report: {
                ...tempPageReport,
                activity_events: [
                  ...tempPageReport.activity_events,
                  onPageView(tempPageReport.page)
                ]
              }
            }
          });
        } else {
          navigator.sendBeacon(
            process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
            JSON.stringify(tempPageReport)
          );
        }
      }
      break;
    case 'pagehide':
      if (tempPageReport) {
        dispatch(clearTrackingData());
        tempPageReport.type = 'PAGE_HIDE';
        tempPageReport.end_time = getCurrentTime();
        tempPageReport.activity_events = activity;
        // navigator.sendBeacon(`${apiUrl}openapi/tracking`, JSON.stringify(tempPageReport));
        if (
          tempPageReport.page &&
          tempPageReport.start_time &&
          !!tempPageReport.token &&
          tempPageReport.token != 0
        ) {
          // navigator.sendBeacon(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, JSON.stringify(tempPageReport));
          if (trackerWorker) {
            trackerWorker.postMessage({
              action: GET_REPORT_DATA,
              data: {
                token: tempPageReport.token,
                report: tempPageReport
              }
            });
          } else {
            navigator.sendBeacon(
              process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
              JSON.stringify(tempPageReport)
            );
          }
        }
        /** send events to firestore  */
      }
      break;
    case 'xhr':
      if (tempPageReport) {
        dispatch(clearTrackingData());

        tempPageReport.type = 'DEFAULT';
        tempPageReport.end_time = getCurrentTime();
        tempPageReport.activity_events = activity;
        /** send events to clirnet db  */
        if (
          tempPageReport.page &&
          tempPageReport.start_time &&
          !!tempPageReport.token &&
          tempPageReport.token != 0
        ) {
          // navigator.sendBeacon(process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT, JSON.stringify(tempPageReport));
          if (typeof TrackerWorker !== 'undefined') {
            trackerWorker.postMessage({
              action: GET_REPORT_DATA,
              data: {
                token: tempPageReport.token,
                report: tempPageReport
              }
            });
            removeSessionStorage('activity_events');
          } else {
            navigator.sendBeacon(
              process.env.REACT_APP_ANALYTICS_REPORTING_ENDPOINT,
              JSON.stringify(tempPageReport)
            );
            removeSessionStorage('activity_events');
          }
        }
      }
      break;
    default:
      break;
  }
};

const getConfigData = (page_name, page_id, appVersion, networkState) => ({
  page: page_name ?? '0',
  page_id: page_id ?? '0',
  referrer: configureReferer(window.location.href) ?? '0',
  start_time: getCurrentTime() ?? '0',
  end_time: getCurrentTime() ?? '0',
  utm: getLocalStorage('utm_source', '') ? getLocalStorage('utm_source', '') : '',
  platform: getAgent(),
  version: appVersion,
  token: getLocalStorage('refreshToken', false) ? getLocalStorage('refreshToken', '') : '0',
  network_info: networkState,
  pageSessionId: pageSessionId,
  sessionId: getSessionStorage('sessionId', 'null')
});
