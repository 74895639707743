import React, { useEffect, useState } from 'react';
import useGetParams from './customHooks/useGetParams';
import { useLocation, useNavigate } from 'react-router-dom';
import useRedirect from './customHooks/useRedirect';
import ContentExploreModal from '../pages/uiv3/medwiki/components/ContentExploreModal';
import { routeNames } from '../router/constants';
import { useGetFreshContentData } from '../Queries/QueryHooks/common.hook';
import { useDispatch } from 'react-redux';
import { storeMainContentData } from '../Store/actions';

function FreshContentWrapper({ children }) {
  const { id, title, main_content_id, main_content_type } = useGetParams();
  const [referedId, setReferedId] = useState(null);
  const [showContentExploreModal, setShowContentExploreModal] = useState(false);
  const navigation = useNavigate();
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();

  const onSuccess = (data) => {
    if (!data?.type_id && !data?.id) {
      redirectTo('Unavailable');
    } else {
      dispatch(storeMainContentData(data));
    }
  };

  const { data: mainDetailsData, isLoading: mainDetailsLoader } = useGetFreshContentData(
    main_content_type,
    referedId,
    onSuccess
  );

  useEffect(() => {
    if (main_content_id) {
      setShowContentExploreModal(true);
      setReferedId(main_content_id);
    }
  }, [main_content_id]);

  const getRedirectPath = () => {
    switch (main_content_type) {
      case 'comp':
        return `/#${routeNames.medwiki.detail}/${main_content_id}`;
      case 'clinical_video':
        return `/#${routeNames.clinicalVideo.detail}/${main_content_id}`;
      case 'gr':
        return `/#${routeNames.grandRound.detail}/${main_content_id}`;
      case 'session':
        return `/#${routeNames.sessions.detail}/${main_content_id}`;
      case 'epub':
        return `/#${routeNames.epaper.detail}/${main_content_id}`;
      case 'spq':
        return `/#${routeNames.survey.detail}/${main_content_id}`;
      case 'training':
        return `/#${routeNames.course.cover}/${main_content_id}`;
      default:
        return `/#${routeNames.medwiki.detail}/${main_content_id}`;
    }
  };

  // Solution 1: Using window.location
  const redirectToMainContent = () => {
    const redirectPath = getRedirectPath();
    if (!redirectPath) return;

    setShowContentExploreModal(false);
    dispatch(storeMainContentData(null));
    window.location.href = redirectPath;
  };

  // Solution 2: Using navigate with reload
  const redirectToMainContentAlt1 = () => {
    const redirectPath = getRedirectPath();
    if (!redirectPath) return;

    setShowContentExploreModal(false);
    navigation(redirectPath, { replace: true });
    window.location.reload();
  };

  // Solution 3: Using state management and key prop
  const [key, setKey] = useState(0);
  const redirectToMainContentAlt2 = () => {
    const redirectPath = getRedirectPath();
    if (!redirectPath) return;

    setShowContentExploreModal(false);
    navigation(redirectPath, { replace: true });
    setKey((prev) => prev + 1);
  };
  console.log('mainDetailsData', mainDetailsData);

  return (
    <>
      {' '}
      {/* Add key prop for Solution 3 */}
      {children}
      {showContentExploreModal && (
        <ContentExploreModal
          show={showContentExploreModal}
          data={mainDetailsData}
          isLoading={mainDetailsLoader}
          onReadMore={() => {
            setShowContentExploreModal(false);
            navigation(location.pathname, { replace: true });

            // alert(location.pathname);
            // window.location.reload();
            // window.location.href = location.pathname; // Force reload on current path
          }}
          setShow={setShowContentExploreModal}
          newContentId={main_content_id}
          redirectToMainContent={redirectToMainContent} // Choose which solution to use
        />
      )}
    </>
  );
}

export default FreshContentWrapper;
