import checkForPremiumIcon from '../common/checkForPremiumIcon';
import { MEDWIKI_NAME } from '../common/common';
import { default_images } from '../common/defaultImages';
import useRedirect from '../components/customHooks/useRedirect';
import { routeNames } from '../router/constants';

export const generateProps = (data, contentAccessRedirect, afterClick = null) => {
  const {
    question,
    image,
    survey_title,
    title,
    type_id,
    survey_id,
    is_locked,
    price,
    user_content_payment
  } = data || {};
  let type = data?.type || data?.trending_type || '';
  if (type === 'comp')
    return {
      internalType: 'comp',
      type_id,
      icon: 'flaticon-medwiki',
      title: question,
      image: image,
      contentType: MEDWIKI_NAME,
      fallbackImage: default_images.medwiki,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.medwiki.detail,
          type_id,
          question,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  else if (type === 'video_archive' || type === 'video')
    return {
      internalType: 'archived_video',
      type_id,
      icon: 'flaticon-clinicalvideo',
      title: question,
      image: image,
      contentType: 'Clinical Video',
      fallbackImage: default_images.clinical,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.clinicalVideo.detail,
          type_id,
          question,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  else if (type === 'gr')
    return {
      internalType: 'gr',
      type_id,
      icon: 'flaticon-grandround',
      title: title,
      image: image,
      contentType: 'Grand Round',
      fallbackImage: default_images.gr,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.grandRound.detail,
          type_id,
          title,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  else if (type === 'survey')
    return {
      internalType: 'survey',
      survey_id: survey_id,
      type_id: survey_id,
      icon: 'flaticon-polls',
      title: survey_title,
      image: image,
      contentType: 'SPQ',
      fallbackImage: data?.category == 'quiz' ? default_images.quiz : default_images.survey,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.spq.detail,
          survey_id,
          survey_title,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  else if (type === 'training') {
    return {
      internalType: 'training',
      type_id,
      icon: 'flaticon-taining',
      title: title,
      image: image,
      contentType: 'Certified Course',
      id: type_id,
      fallbackImage: default_images.training,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.course.content,
          type_id,
          title,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  } else if (type == 'session') {
    return {
      internalType: 'session',
      type_id: data?.session_id,
      icon: 'flaticon-livecme',
      title: data?.session_topic,
      image: Array.isArray(data?.cover_image)
        ? data?.cover_image.filter((item) => item && item != '')[0] || ''
        : data?.cover_image,
      contentType: 'Live CME',
      id: data?.session_id,
      fallbackImage: default_images.session,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.sessions.detail,
          data?.session_id,
          data?.session_topic,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  } else if (type == 'epub') {
    return {
      internalType: 'epub',
      type_id: data?.type_id,
      icon: 'flaticon-ebook',
      title: data?.title,
      image: data?.image,
      contentType: 'Epaper',
      id: data?.type_id,
      fallbackImage: default_images.epub,
      premiumContent: is_locked,
      displayIcon: checkForPremiumIcon(is_locked),
      price: price,
      userContentPayment: user_content_payment,
      onClick: () => {
        afterClick && afterClick();
        contentAccessRedirect(
          routeNames.epaper.detail,
          data?.type_id,
          data?.title,
          is_locked,
          price,
          user_content_payment
        );
      }
    };
  }
};
