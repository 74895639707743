export const commonConstants = {
  CLEAR_ARRAY_SUCCESS: 'CLEAR_ARRAY_SUCCESS',

  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  GET_COMMENTS_FALIURE: 'GET_COMMENTS_FALIURE',

  GET_REPLY_COMMENTS_SUCCESS: 'GET_REPLY_COMMENTS_SUCCESS',
  GET_REPLY_COMMENTS_FALIURE: 'GET_REPLY_COMMENTS_FALIURE',

  POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS',
  POST_COMMENT_FAILURE: 'POST_COMMENT_FAILURE',
  GET_POINTS: 'GET_POINTS',
  POINTS_API_CALLED: 'POINTS_API_CALLED',

  UPDATE_CURRENT_MODULE: 'UPDATE_CURRENT_MODULE',

  TOOGLE_SPEAKER_INVITATION_MODAL: 'TOOGLE_SPEAKER_INVITATION_MODAL',

  SEND_SPEAKER_INVITATION_SUCCESS: 'SEND_SPEAKER_INVITATION_SUCCESS',
  SEND_SPEAKER_INVITATION_FALIURE: 'SEND_SPEAKER_INVITATION_FALIURE',

  CTA_FETCH_SUCCESS: 'CTA_FETCH_SUCCESS',
  CTA_FETCH_FAILED: 'CTA_FETCH_FAILED',
  CTA_ANALYTICS_POTS: 'CTA_ANALYTICS_POTS',
  CTA_VIEWED: 'CTA_VIEWED',
  CTA_FETCH_LOADING: 'CTA_FETCH_LOADING',

  RENDER_HEADER: 'RENDER_HEADER',

  REFERENCE_HANDLER: 'REFERENCE_HANDLER',

  TOGGLE_SEARCH_OPEN: 'TOGGLE_SEARCH_OPEN',
  ADD_TO_CTA: 'ADD_TO_CTA',

  TOGGLE_MORE_MOBILE: 'TOGGLE_MORE_MOBILE',

  RELATED_FETCH_SUCCESS: 'RELATED_FETCH_SUCCESS',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

  RESET_STORE: 'RESET_STORE',

  TOGGLE_COMMENT_FOCUS: 'TOGGLE_COMMENT_FOCUS',

  TOGGLE_EXPLORE_KEY: 'TOGGLE_EXPLORE_KEY',
  TOGGLE_LOADING_BAR: 'TOGGLE_LOADING_BAR',

  SHOW_COMMENTS: 'SHOW_COMMENTS',

  STORE_MAIN_CONTENT_DATA: 'STORE_MAIN_CONTENT_DATA'
};
